import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, firstValueFrom } from "rxjs";
import { DecoratorHelper } from "../../../core/helpers/decorator.helper";
import { TPermissions } from "../../../core/types/permissions.type";
import { Permissions } from "../../../core/models/permissions.model";
import { selectAuthUser } from 'src/app/state/auth/auth.selectors';
import { Nullable } from "src/app/core/types/nullable.type";
import { IAppState } from "src/app/state/app/app.type";
import { UserRole } from "src/app/core/enums/user-role.enum";
import { User } from "src/app/core/models/user.model";



/**
 * @service
 * @description
 * Permission management.
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  //#region Properties

  /**
   * @description
   * The instance that holds all of the permissions on run time.
   * 
   * This is very important as it invokes the user permission decorators
   * which categorizes and saves all permissions in their respective role.
   * 
   * This should be done only one time.
   */
  private static readonly permissions = new Permissions();

  /**
   * @description
   * The user state.
   * Fetched directly from the auth store.
   */
  private user$: Observable<Nullable<User>>;

  //#endregion

  //#region Lifecycle

  constructor(private store: Store<IAppState>) {
    this.user$ = this.store.select(selectAuthUser);
  }

  //#endregion

  //#region Methods

  /**
   * @description
   * Checks if a certain permission belongs to a user role.
   *
   * @param permissions The name of the permission
   * @returns True if the user role has access to the permission
   */
  async has(permissions: TPermissions | Array<TPermissions>): Promise<boolean> {
    const user = await firstValueFrom(this.user$) ?? new User();

    return Array.isArray(permissions)
      ? permissions.some(e => DecoratorHelper.hasPermission(PermissionsService.permissions, e, user?.role))
      : DecoratorHelper.hasPermission(PermissionsService.permissions, permissions, user?.role);
  }

  /**
   * @description
   * Returns all permissions that belong
   * to a user role.
   * 
   * @param role The target user role
   */
  get(role: UserRole): Array<TPermissions> {
    return DecoratorHelper.getPermissions(PermissionsService.permissions, role)?.filter(e => e !== 'none');
  }

  //#endregion
}