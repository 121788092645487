import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrl } from '../../enums/api-url.enum';
import { Observable, firstValueFrom } from 'rxjs';
import { IProject } from '../../types/project.type';

/**
 * @service
 * @description
 * Handles requests for the projects controller
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectApiService {
  //#region Lifecycle

  constructor(private _http: HttpClient) {}

  //#endregion

  //#region Methods

  /**
   * @description
   * Fetches a list of all projects
   */
  load(): Observable<Array<IProject>> {
    return this._http.get<Array<IProject>>(`${ApiUrl?.Projects}`);
  }
  /**
   * @description
   * Create new project
   * @param project The project to add
   */
  createProject(project: IProject) {
    return this._http.post(`${ApiUrl?.Projects}`, project);
  }
  /**
   * @description
   * Delete project by id
   * @param projectId The project Id to delete
   */
  deleteProject(projectId: number): Observable<any> {
    return this._http.delete(`${ApiUrl?.Projects}/${projectId}`);
  }
  /**
   * @description
   * Update an existing project
   * @param projectId The project Id to update
   */
  updateExistingProject(project: IProject) {
    return this._http.put(`${ApiUrl?.Projects}`, project);
  }
  /**
   * @description
   * Get project by ID
   * @param projectId The project Id to get
   */
  getProjectById(projectId: number): Observable<IProject> {
    return this._http.get<IProject>(`${ApiUrl?.Projects}/${projectId}`);
  }
  /**
   * @description
   * Uplad list of project
   */
  upload(project: any): Promise<any> {
    return firstValueFrom(this._http.post(ApiUrl.ProjectsUpload, project));
  }
  /**
   * @description
   * Download list of project
   * @param project Project details to download
   */
  download(project: IProject): Observable<any> {
    return this._http.get(
      `${ApiUrl.Projects}/${project.name}/${project.environment}/import`,
      {
        responseType: 'blob',
      }
    );
  }
  /**
   * @description
   * Clone an existing project
   * @param project The project to details
   * @param projectName The project envirement
   * @param projectEnv The new project name
   */
  cloneProject(project: IProject, projectName: string, projectEnv: string) {
    return this._http.post(
      `${ApiUrl?.Projects}/${projectName}/${projectEnv}/clone`,
      project
    );
  }

  //#endregion
}
