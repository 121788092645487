import { on } from '@ngrx/store';
import { IHelperState } from './helper.type';
import { DarkModechanged, darkModeSuccess } from './helper.action';
import { createRehydrateReducer } from 'src/app/core/helpers/state.helper';
import { StorageKey } from 'src/app/core/enums/storage-key.enum';

export const initialState: IHelperState = {
  darkMode: localStorage.getItem('darkMode') === 'true' || false,
};

export const helperReducer = createRehydrateReducer(
  StorageKey.helper,
  initialState,
  on(DarkModechanged, (state, { content }) => ({
    ...state,
    darkMode: content,
  })),
  on(darkModeSuccess, (state, action) => ({
    ...state,
  }))
);
