import { environment } from 'src/environments/environment';

/**
 * @enum
 * @description
 * API URLs
 */
export const ApiUrl = {
  //#region Auth
  Authenticate: `${environment.api.backend}/auth/login`,
  //#endregion

  //#region Users
  Users: `${environment.api.backend}/users`,
  UsersProjects: `${environment.api.backend}/users/updateProject`, // TODO Check endpoints
  //#endregion

  //#region Projects
  Projects: `${environment.api.backend}/projects`,
  ProjectsUpload: `${environment.api.backend}/projects/upload`,
  //#endregion

  //#region Dictionaries
  Dictionaries: `${environment.api.backend}/dictionaries`,
  //#endregion

  //#region Steps
  Steps: `${environment.api.backend}/steps`,
  //#endregion

  //#region Pages
  Pages: `${environment.api.backend}/pages`,
  //#endregion

  //#region Component
  Components: `${environment.api.backend}/components`,
  //#endregion

  //#region gatling
  Gatling: `${environment.api.backend}/gatling`,
  //#endregion

  //#region Features
  Features: `${environment.api.backend}/features`,
  //#endregion

  //#region Reports
  Reports: `${environment.api.backend}/reports`,
  //#endregion

  //#region Reports
  Gemini: `${environment.api.ai}/gemini`
  //#endregion
};
