export const environment = {
  api: {
    backend: 'https://dev-buddy-automation-platform.westeurope.cloudapp.azure.com/api',
    ai: 'https://dev-buddy-automation-platform.westeurope.cloudapp.azure.com/ai',
  },
  jenkins: {
    token: '1£43fgh',
    apiTestJob: 'Dashboard API test',
    endToEndTestJob: 'Dashboard End-to-end test',
    buddyGreenEndTestJob: 'BuddyGreen End-to-end test',
    loadTestJob: 'Dashboard API load test',
    endToEndRegressionSuiteTestJob:
      'Dashboard End-to-end regression suite test',
    apiRegressionSuiteTestJob: 'Dashboard API regression suite test',
    endpoint: 'https://buddy.westeurope.cloudapp.azure.com:8443',
  },
};
