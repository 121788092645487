<div class="dialog">
  <div mat-dialog-title class="dialog__head">Select component to attach</div>
  <div mat-dialog-content class="dialog__body">
    <form class="p-3 w-full">
      <mat-form-field class="w-full" appearance="outline">
        <input
          type="text"
          matInput
          [formControl]="componentCtrl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option?.name"
          >
            {{ option?.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="componentCtrl.hasError('required')">
          Component is required
        </mat-error>
        <mat-error
          *ngIf="
            !componentCtrl.hasError('required') &&
            componentCtrl.hasError('invalidAutocompleteString')
          "
        >
          Component not recognized. Click one of the autocomplete options.
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="dialog__foot">
    <button mat-button mat-dialog-close>Cancel</button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="!componentCtrl.valid"
      (click)="save()"
    >
      Save
    </button>
  </div>
</div>
