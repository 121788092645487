import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { User } from 'src/app/core/models/user.model';
import { selectAuthToken, selectAuthUser } from 'src/app/state/auth/auth.selectors';
import { IAppState } from 'src/app/state/app/app.type';
import { logout as signout } from 'src/app/state/auth/auth.action';
import { IJWTUser } from 'src/app/core/types/jwt-user.type';
import { UserRole } from 'src/app/core/enums/user-role.enum';




/**
 * @description
 * Handles authentication and connected
 * user checks.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //#region Lifecycle

  constructor(
    private jwt: JwtHelperService,
    private store: Store<IAppState>
  ) { }

  //#endregion

  //#region Methods

  /**
   * @description
   * Checks if the user is logged-in
   *
   * @returns True of logged-in
   */
  isLoggedIn(): Observable<boolean> {
    return new Observable(observer => {
      firstValueFrom(this.store.select(selectAuthToken))
        .then(e => {
          const expired = this.jwt.isTokenExpired(e);
          observer.next(Boolean(e && !expired));
        });
    });
  }

  /**
   * @description
   * Checks if the connected user is of a specific role
   *
   * @param role The user role to check against
   * @returns True of the user matches a specific role
   */
  is(role: UserRole): Observable<boolean> {
    return new Observable(observer => {
      firstValueFrom(this.store.select(selectAuthUser))
        .then(e => {
          observer.next(e?.role === role);
        });
    });
  }

  /**
   * @description
   * Dispatches the logout action
   */
  logout(): void {
    this.store.dispatch(signout());
  }

  /**
   * @description
   * Decodes the JWT into a user instance
   *
   * @param jwt The JSON web token input
   * @returns The instantiated user object
   */
  getUser(jwt: string): User {
    const decodedToken = this.jwt.decodeToken<IJWTUser>(jwt);
    const user = new User({ email: decodedToken?.sub, role: decodedToken?.role, projects: decodedToken?.projects });

    return user;
  }

  //#endregion
}
