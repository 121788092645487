import { Component, Input } from '@angular/core';



@Component({
  selector: '[appEmpty]',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent {

  //#region

  /**
   * @description
   * The display state
   */
  @Input()
  appEmpty: boolean;

  /**
   * @description
   * The display message
   */
  @Input()
  message: string;

  //#endregion

  //#region Lifecycle

  constructor() {
    this.appEmpty = true;
    this.message = 'No data';
  }

  //#endregion
}
