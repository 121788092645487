import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  //#region Properties

  /**
   * @description
   * Dialog title
   */
  title: string;

  /**
   * @description
   * Dialog message
   */
  message: string;

  /**
   * @description
   * Confirmation label
   */
  confirmButtonText: string;

  /**
   * @description
   * Cancellation 
   */
  cancelButtonText: string;

  //#endregion

  //#region Lifecycle

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    this.message = data?.message ?? 'Are you sure?';
    this.title = data?.title ?? 'Confirmation';
    this.confirmButtonText = data?.buttonText?.ok ?? 'Confirm';
    this.cancelButtonText = data?.buttonText?.cancel ?? 'Cancel';
  }

  //#endregion

  //#region Event listeners

  /**
   * @description
   * Confirms the dialog
   */
  onConfirm(): void {
    this.dialogRef.close(true);
  }

  //#endregion
}
