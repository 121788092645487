import { UserRole } from "../enums/user-role.enum";
import { TPermissions } from "../types/permissions.type";
import { DecoratorHelper } from "../helpers/decorator.helper";



/* @decorator
 * @description
 * Stores a permission for the admin role.
 *
 * @param target The host object that will contain the permissions
 * @param permission The permission to store
 */
export function AdminPermission(target: any, permission: TPermissions) {
  DecoratorHelper.addPermission(target, permission, UserRole.Admin);
}