import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, first, mergeMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/state/app/app.type';
import { selectAuthToken } from 'src/app/state/auth/auth.selectors';
import { ApiUrl } from '../enums/api-url.enum';



/**
 * @interceptor
 * @description
 * Intercepts requests and attaches the token
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  //#region Lifecycle

  constructor(private store: Store<IAppState>) { }

  //#endregion

  //#region Methods

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store
      .select(selectAuthToken)
      .pipe(
        first(),
        mergeMap((token: string) => {
          let headerValues: any = {
            'Content-Type': 'application/json',
          };

          if (!request.url.endsWith(ApiUrl.Authenticate)) {
            headerValues = {
              ...headerValues,
              'Authorization': `Bearer ${token}`
            };
          }

          const headers = new HttpHeaders(headerValues);
          const newRequest = request.clone({ headers });

          return next.handle(newRequest);
        })
      );
  }

  //#endregion
}
