/**
 * @enum
 * @description
 * The toast types
 */
export enum ToastType {

  /**
   * @description
   * The default normal toast
   */
  Normal,

  /**
   * @description
   * The success toast
   */
  Success,

  /**
   * @description
   * The alert toast
   */
  Alert,

  /**
   * @description
   * The toast that represent danger
   */
  Error
}