import { authReducer } from './auth/auth.reducer';
import { helperReducer } from './helper/helper.reducer';
import { projectDetailReducer } from './project-detail/project-detail.reducer';
import { reportReducer } from './reports/report.reducer';

export const reducers = {
  auth: authReducer,
  report: reportReducer,
  helper: helperReducer,
  projectDetail: projectDetailReducer,
};
