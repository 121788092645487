import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, tap, } from 'rxjs/operators';
import { Page } from 'src/app/core/enums/page.enum';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { AuthAction, loginFailure, loginInvalid, loginSession, loginSuccess } from './auth.action';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AuthApiService } from 'src/app/core/api/auth/auth-api.service';



@Injectable()
export class AuthEffects {

  //#region Properties

  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthAction.Login),
    switchMap((action: any, i: number) => {
      return this.authApiService.login(action.email, action.password).pipe(
        map(({ jwt }) => loginSuccess({ token: jwt })),
        catchError(() => of(loginFailure()))
      )
    })
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthAction.LoginSuccess),
    map(({ token }) => {
      const user = this.authService.getUser(token);
      return loginSession({ user });
    })
  ));

  loginSession$ = createEffect(() => this.actions$.pipe(
    ofType(AuthAction.LoginSession),
    tap(() => this.router.navigate([`/${Page.Home}`], { replaceUrl: true }))
  ), { dispatch: false });

  loginFailure$ = createEffect(() => this.actions$.pipe(
    ofType(AuthAction.LoginFailure),
    map((action: any, i: number) => loginInvalid({ invalid: true }))
  ));

  loginInvalid$ = createEffect(() => this.actions$.pipe(
    ofType(AuthAction.LoginFailure),
    tap(() => this.toastService.show('Sorry, login failed, please check your credentials', { type: ToastType.Error }))
  ), { dispatch: false });

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthAction.Logout),
    tap(() => this.router.navigate([`/${Page.SignIn}`], { replaceUrl: true }))
  ), { dispatch: false });

  //#endregion

  //#region Lifecycle

  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService,
    private toastService: ToastService,
    private authApiService: AuthApiService
  ) { }

  //#endregion
}
