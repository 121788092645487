import { UserPermission } from '../decorators/user-permission.decorator';
import { AdminPermission } from '../decorators/admin-permission.decorator';
import { MaintainerPermission } from '../decorators/maintainer-permission.decorator';

/**
 * @model
 * @description
 * The permissions class that contains all individual
 * permission keys and their respective user role decorators.
 */
export class Permissions {
  /**
   * @description
   * This is the default permission, all users
   * must have access to it.
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  none!: boolean;

  /**
   * @description
   * If the user can view projects
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  projectView!: boolean;

  /**
   * @description
   * If the user can import a project
   */
  @AdminPermission
  @MaintainerPermission
  projectImport!: boolean;

  /**
   * @description
   * If the user can create a new project
   */
  @AdminPermission
  @MaintainerPermission
  projectCreate!: boolean;

  /**
   * @description
   * If the user can update an existing project
   */
  @AdminPermission
  @MaintainerPermission
  projectUpdate!: boolean;

  /**
   * @description
   * If the user can clone an existing project
   */
  @AdminPermission
  @MaintainerPermission
  projectClone!: boolean;
  /**
   * @description
   * If the user can download  project
   */
  @AdminPermission
  @MaintainerPermission
  projectDownload!: boolean;

  /**
   * @description
   * If the user delete an existing project
   */
  @AdminPermission
  @MaintainerPermission
  projectDelete!: boolean;

  /**
   * @description
   * If the user can create a new page on project details
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  pageCreate!: boolean;
  /**
   * @description
   * If the user can update an existing page on project details
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  pageUpdate!: boolean;
  /**
   * @description
   * If the user can delete a page on project details
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  pageDelete!: boolean;
  /**
   * @description
   * If the user can attach component to page on project details
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  attachComponentToPage!: boolean;
  /**
   * @description
   * If the user can detach component from page on project details
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  detachComponentFromPage!: boolean;

  /**
   * @description
   * If the user can create a new component on project details
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  componentCreate!: boolean;

  /**
   * @description
   * If the user can update an existing component on project details
   */
  @AdminPermission
  @MaintainerPermission
  componentUpdate!: boolean;

  /**
   * @description
   * If the user can delete an existing component on project details
   */
  @AdminPermission
  @MaintainerPermission
  componentDelete!: boolean;
  /**
   * @description
   * If the user can attach component to parent componet on project details
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  attachComponentToComponent!: boolean;
  /**
   * @description
   * If the user can detach component from parent componet on project details
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  detachComponentFromComponent!: boolean;

  /**
   * @description
   * If the user can view the dictionaries page
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  dictionaryView!: boolean;

  /**
   * @description
   * If the user can download a dictionary
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  dictionaryDownload!: boolean;

  /**
   * @description
   * If the user can create a new dictionary
   */
  @AdminPermission
  @MaintainerPermission
  dictionaryCreate!: boolean;

  /**
   * @description
   * If the user can update an existing dictionary
   */
  @AdminPermission
  @MaintainerPermission
  dictionaryUpdate!: boolean;

  /**
   * @description
   * If the user can delete an existing dictionary
   */
  @AdminPermission
  @MaintainerPermission
  dictionaryDelete!: boolean;

  /**
   * @description
   * If the user can add a new step in a dictionary
   */
  @AdminPermission
  @MaintainerPermission
  stepCreate!: boolean;

  /**
   * @description
   * If the user can update an existing step in a dictionary
   */
  @AdminPermission
  @MaintainerPermission
  stepUpdate!: boolean;

  /**
   * @description
   * If the user can delete an existing step in a dictionary
   */
  @AdminPermission
  @MaintainerPermission
  stepDelete!: boolean;

  /**
   * @description
   * If the user can view reports
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  reportView!: boolean;

  /**
   * @description
   * If the user can delete reports
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  reportDelete!: boolean;

  /**
   * @description
   * If the user can view the users page
   */
  @AdminPermission
  @MaintainerPermission
  userView!: boolean;

  /**
   * @description
   * If the user can create a new user
   */
  @AdminPermission
  userCreate!: boolean;

  /**
   * @description
   * If the user can update an existing user
   */
  @AdminPermission
  userUpdate!: boolean;

  /**
   * @description
   * If the user can delete an existing user
   */
  @AdminPermission
  userDelete!: boolean;

  /**
   * @description
   * If the user can projects to existing users
   */
  @AdminPermission
  @MaintainerPermission
  userProjectAssign!: boolean;

  /**
   * @description
   * If the user can run the feature
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  featureRun!: boolean;

  /**
   * @description
   * If the user can view the features page
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  featureView!: boolean;

  /**
   * @description
   * If the user can create a new feature
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  featureCreate!: boolean;

  /**
   * @description
   * If the user can update an existing feature
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  featureUpdate!: boolean;

  /**
   * @description
   * If the user can delete an existing feature
   */
  @UserPermission
  @AdminPermission
  @MaintainerPermission
  featureDelete!: boolean;
}
