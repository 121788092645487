import { on } from '@ngrx/store';
import { IProjectDetailState } from './project-detail.type';
import { createReducer } from '@ngrx/store';
import {
  setProjectId,
  getProjectInfo,
  getProjectInfoSuccess,
  getProjectInfoFailure,
  getProjectPages,
  getProjectPagesSuccess,
  getProjectPagesFailure,
  getProjectComponents,
  getProjectComponentsSuccess,
  getProjectComponentsFailure,
  emptyProjectDetail,
  setTestRun,
} from './project-detail.action';

export const initialState: IProjectDetailState = {
  id: null,
  error: null,
  isLoading: false,
  isTestRun: false,
  isLoadingComponents: false,
  isLoadingPages: false,
  projectInfo: null,
  projectPages: [],
  projectComponents: [],
};

export const projectDetailReducer = createReducer(
  initialState,
  on(emptyProjectDetail, (state) => ({
    ...state,
    ...initialState,
  })),
  on(setProjectId, (state, { id }) => ({
    ...state,
    id: id,
  })),
  // Project Info

  on(getProjectInfo, (state, { projectId }) => ({
    ...state,
    isLoading: true,
  })),
  on(getProjectInfoSuccess, (state, { projectInfo }) => ({
    ...state,
    isLoading: false,
    projectInfo: projectInfo,
  })),
  on(getProjectInfoFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  // Project Pages

  on(getProjectPages, (state, { projectId }) => ({
    ...state,
    isLoadingPages: true,
  })),
  on(getProjectPagesSuccess, (state, { projectPages }) => ({
    ...state,
    isLoadingPages: false,
    projectPages: [...projectPages],
  })),
  on(getProjectPagesFailure, (state, { error }) => ({
    ...state,
    isLoadingPages: false,
  })),
  // Project Components
  on(getProjectComponents, (state, { projectId }) => ({
    ...state,
    isLoadingComponents: true,
  })),
  on(getProjectComponentsSuccess, (state, { projectComponents }) => ({
    ...state,
    isLoadingComponents: false,
    projectComponents: [...projectComponents],
  })),
  on(getProjectComponentsFailure, (state, { error }) => ({
    ...state,
    isLoadingComponents: false,
  })),
  on(setTestRun, (state, { isRun }) => ({
    ...state,
    isTestRun: isRun,
  }))
);
