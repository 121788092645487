import { createAction, props } from '@ngrx/store';

export enum HelperAction {
  DarkModechanged = '[App] Dark Mode changed',
  DarkModeSuccess = '[App] Dark Mode Success',
}

export const DarkModechanged = createAction(
  HelperAction.DarkModechanged,
  props<{ content: boolean }>()
);
export const darkModeSuccess = createAction(HelperAction.DarkModeSuccess);
