<div class="dialog">
  <div mat-dialog-title class="dialog__head">Load Test</div>

  <div mat-dialog-content class="dialog__body">
    <form class="group relative gap-3" [formGroup]="form">
      <div class="flex flex-row gap-2 items-center">
        <mat-form-field class="input -mb-3" appearance="outline">
          <mat-icon matPrefix>commit</mat-icon>
          <mat-label>Executor Count</mat-label>
          <input matInput formControlName="executorCount" placeholder="Ex: 1" />
        </mat-form-field>
        <span class="flex justify-center w-40 text-sm items-center">
          Default : 1
        </span>
      </div>
      <div class="flex flex-row gap-2 items-center">
        <mat-form-field class="input -mb-3" appearance="outline">
          <mat-icon matPrefix>group</mat-icon>
          <mat-label>Ramp Users</mat-label>
          <input matInput formControlName="rampUsers" placeholder="Ex: 10" />
        </mat-form-field>
        <span class="flex justify-center w-40 text-sm items-center">
          Default : 10
        </span>
      </div>
      <div class="flex flex-row gap-2 items-center">
        <mat-form-field class="input -mb-3" appearance="outline">
          <mat-icon matPrefix>timer</mat-icon>
          <mat-label>Duration</mat-label>
          <input matInput formControlName="duration" placeholder="Ex: 5" />
        </mat-form-field>
        <span class="flex justify-center w-40 text-sm items-center">
          Default : 5
        </span>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="dialog__foot">
    <button mat-button mat-dialog-close>Cancel</button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      (click)="onRun()"
    >
      Run
    </button>
  </div>
</div>
