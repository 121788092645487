import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/core/models/user.model';



export enum AuthAction {
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  LoginInvalid = '[Auth] Login invalid',
  LoginSuccess = '[Auth] Login success',
  LoginSession = '[Auth] Login session',
  LoginFailure = '[Auth] Login failure'
}

export const login = createAction(AuthAction.Login, props<{ email: string, password: string }>());
export const loginInvalid = createAction(AuthAction.LoginInvalid, props<{ invalid: boolean }>());
export const loginSuccess = createAction(AuthAction.LoginSuccess, props<{ token: string }>());
export const loginSession = createAction(AuthAction.LoginSession, props<{ user: User }>());
export const loginFailure = createAction(AuthAction.LoginFailure);
export const logout = createAction(AuthAction.Logout);