import { createAction, props } from '@ngrx/store';

export enum ReportsAction {
  GetProjects = '[Reports] GetProjects',
  GetProjectsSuccess = '[Reports] GetProjectsSuccess',
  GetProjectsFailure = '[Reports] GetProjectsFailure',
}

export const getProjects = createAction(ReportsAction.GetProjects);

export const getProjectsSuccess = createAction(
  ReportsAction.GetProjectsSuccess,
  props<{ projectList: any[] }>()
);

export const getProjectsFailure = createAction(
  ReportsAction.GetProjectsFailure,
  props<{ error: any }>()
);
