import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page } from './core/enums/page.enum';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { GuestGuard } from './shared/guards/guest/guest.guard';
import { PermissionsGuard } from './shared/guards/permissions/permissions.guard';



const routes: Routes = [
  { path: '', redirectTo: Page.Home, pathMatch: 'full' },
  {
    path: Page.Home,
    data: { animation: 'HomeModule' },
    canActivateChild: [AuthGuard, PermissionsGuard],
    loadChildren: () => import('./pages/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: Page.SignIn,
    canActivateChild: [GuestGuard],
    data: { animation: 'LoginModule' },
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  { path: '**', redirectTo: Page.Home },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
