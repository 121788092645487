import {Observable, firstValueFrom} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {IFeature} from '../../types/feature.type';
import {IFeatureCreateRequest} from '../../types/requests/feature-create-request.type';
import {IFeatureUpdateRequest} from '../../types/requests/feature-update-request.type';
import {IProject} from '../../types/project.type';
import {ApiUrl} from "../../enums/api-url.enum";
import {GeminiResponse, GenerateFeatureRequest} from "../../models/gemini.model";

@Injectable({
  providedIn: 'root',
})
/**
 * @service
 * @description
 * Handles requests for the Feature controller
 */
export class FeatureService {
  //#region Lifecycle

  constructor(private _http: HttpClient) {
  }

  //#endregion

  //#region Methods

  /**
   * @description
   * Create new feature
   * @param feature Feature details to add
   */

  create(feature: IFeatureCreateRequest): Promise<IFeature> {
    return firstValueFrom(
      this._http.post(ApiUrl.Features, feature)
    ) as Promise<IFeature>;
  }

  /**
   * @description
   * Update an existing feature
   *  @param feature Feature to update
   */
  update(feature: IFeatureUpdateRequest): Promise<IFeature> {
    return firstValueFrom(
      this._http.put(ApiUrl.Features, feature)
    ) as Promise<IFeature>;
  }

  /**
   * @description
   * Get features list by project ID
   *@param projectId Project Id to get features
   */
  getFeaturesListByProjectId(projectId: string): Observable<IFeature[]> {
    return this._http.get(
      `${ApiUrl.Features}/${projectId}`
    ) as Observable<IFeature[]>;
  }

  /**
   * @description
   * Get Feature deatails by feature ID
   * @param projectId Project Id
   * @param featureId Feature Id
   */
  getFeaturesDetail(
    projectId: number,
    featureId: number
  ): Observable<IFeature> {
    return this._http.get(
      `${ApiUrl.Features}/${projectId}/${featureId}`
    ) as Observable<IFeature>;
  }

  /**
   * @description
   * Delete an existing feature
   * @param projectId Project Id
   * @param featureId Feature Id
   */

  deleteFeature(featureId: string, projectId: string) {
    return this._http.delete(
      `${ApiUrl.Features}/${projectId}/${featureId}`
    );
  }

  /**
   * @description
   * Generate gherking code by ChatGPT
   *  @param input Text to generate from the gherkin code
   */
  generateGherkin(input: GenerateFeatureRequest): Observable<GeminiResponse> {
    return this._http.post(
      `${ApiUrl.Gemini}/generate`,
      input,
    ) as Observable<GeminiResponse>;
  }

  runJenkinsTests(
    feature: IFeature,
    projectName: string,
    projectEnv: string,
    jenkinsUrl: string
  ) {
    const params = {
      token: environment.jenkins.token,
      job: feature.isApi
        ? environment.jenkins.apiTestJob
        : environment.jenkins.endToEndTestJob,
      BUDDY_PROJECT_NAME: projectName,
      BUDDY_PROJECT_ENVIRONMENT: projectEnv,
      BUDDY_PROJECT_ID: feature.projectId?.toString(),
      BUDDY_FEATURE_ID: feature.id?.toString(),
    };
    const param = new URLSearchParams(params).toString();
    return this._http.post(
      `${jenkinsUrl}/buildByToken/buildWithParameters?${param}`,
      {}
    );
  }

  runBuDDyGreenTests(feature: IFeature, project: IProject) {
    const params = {
      token: environment.jenkins.token,
      job: environment.jenkins.buddyGreenEndTestJob,
      BUDDY_PROJECT_NAME: project.name,
      BUDDY_PROJECT_ENVIRONMENT: project.environment,
      CONTAINER_REGISTRY_URL: project?.containerRegistryUrl || '',
      GITLAB_USERNAME: project?.gitlabUsername || '',
      GITLAB_TOKEN: project?.gitlabToken || '',
      BUDDY_PROJECT_ID: feature.projectId?.toString(),
      BUDDY_FEATURE_ID: feature.id?.toString(),
    };
    const param = new URLSearchParams(params).toString();
    return this._http.post(
      `${project?.jenkinsUrl}/buildByToken/buildWithParameters?${param}`,
      {}
    );
  }

  runLoadTest(feature: IFeature, project: IProject, parameters: any) {
    const params = {
      token: environment.jenkins.token,
      job: environment.jenkins.loadTestJob,
      BUDDY_PROJECT_NAME: project.name,
      BUDDY_PROJECT_ENVIRONMENT: project.environment,
      EXECUTOR_COUNT: parameters?.executorCount || '1',
      RAMP_USERS: parameters?.rampUsers || '10',
      DURATION: parameters?.duration || '5',
      BUDDY_PROJECT_ID: project.id?.toString(),
      BUDDY_FEATURE_ID: feature.id?.toString(),
    };
    const param = new URLSearchParams(params).toString();
    return this._http.post(
      `${project?.jenkinsUrl}/buildByToken/buildWithParameters?${param}`,
      {}
    );
  }

  runRegressionSuitTest(
    isEndTond: boolean,
    projectName: string,
    projectId: number,
    projectEnv: string,
    jenkinsUrl: string
  ) {
    const params = {
      token: environment.jenkins.token,
      job: isEndTond
        ? environment.jenkins.endToEndRegressionSuiteTestJob
        : environment.jenkins.apiRegressionSuiteTestJob,
      BUDDY_PROJECT_NAME: projectName,
      BUDDY_PROJECT_ENVIRONMENT: projectEnv,
      BUDDY_PROJECT_ID: projectId.toString(),
    };
    const param = new URLSearchParams(params).toString();
    return this._http.post(
      `${jenkinsUrl}/buildByToken/buildWithParameters?${param}`,
      {}
    );
  }
}
