import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HelperAction, darkModeSuccess } from './helper.action';

@Injectable()
export class HelperEffects {
  DarkMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelperAction.DarkModechanged),
      map(() => darkModeSuccess())
    )
  );

  DarkModeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HelperAction.DarkModeSuccess),
        tap(() => {
          document.documentElement.classList.toggle('dark');
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
