import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentApiService } from 'src/app/core/api/component/component-api.service';
import { ToastService } from '../../services/toast/toast.service';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { EMPTY, catchError, tap } from 'rxjs';
@Component({
  selector: 'app-new-component',
  templateUrl: './new-component.component.html',
})
export class NewComponentComponent {
  public form: FormGroup;
  public isEdit = false;
  public stockType = [
    { name: 'Multiple', value: true },
    { name: 'Single', value: false },
  ];
  public get f(): any {
    return this.form.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<NewComponentComponent>,
    private _fb: FormBuilder,
    private _componentApiService: ComponentApiService,
    private _toastService: ToastService
  ) {
    this.form = this._fb.group({
      name: ['', Validators.required],
      selector: ['', Validators.required],
      isCollection: ['', Validators.required],
    });
    if (this.data?.isEdit) {
      this.isEdit = true;
      this.form.patchValue({
        ...this.data?.component,
        isCollection: this.stockType.find(
          (f) => f.value === this.data.component?.isCollection
        ),
      });
    }
  }

  onNameType(e: Event): void {
    if (e.isTrusted) {
      const value = (e?.target as HTMLInputElement)?.value ?? '';
      const sanitizedValue = value.replace(/\s/gi, '-');

      this.form.patchValue({ name: sanitizedValue });
    }
  }

  createComponent() {
    if (!this.form.valid) {
      this._toastService.show('Please fill all required inputs.', {
        type: ToastType.Error,
      });
      return;
    }
    this._componentApiService
      .createNewComponent({
        ...this.form.value,
        isCollection: this.form.value?.isCollection?.value,
        projectId: +this.data?.projectId,
      })
      .pipe(
        catchError((err) => {
          this._toastService.show(
            'Something went wrong while creating this component.',
            { type: ToastType.Error }
          );
          return EMPTY;
        }),
        tap(() => {
          this._toastService.show(
            `${this.form.value?.name} component successfully created.`,
            {
              type: ToastType.Success,
            }
          );
          this.dialogRef.close(true);
        })
      )
      .subscribe();
  }
  editComponent() {
    if (!this.form.valid) {
      this._toastService.show('Please fill all required inputs.', {
        type: ToastType.Error,
      });
      return;
    }
    this._componentApiService
      .updateExistingComponent({
        ...this.form.value,
        isCollection: this.form.value?.isCollection?.value,
        projectId: +this.data?.projectId,
        id: this.data?.component?.id,
      })
      .pipe(
        catchError((err) => {
          this._toastService.show(
            'Something went wrong while editing this component.',
            { type: ToastType.Error }
          );
          return EMPTY;
        }),
        tap(() => {
          this._toastService.show(
            `${this.form.value?.name} component successfully edited.`,
            {
              type: ToastType.Success,
            }
          );
          this.dialogRef.close(true);
        })
      )
      .subscribe();
  }
}
