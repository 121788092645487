import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Page } from 'src/app/core/enums/page.enum';
import { TPermissions } from 'src/app/core/types/permissions.type';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { Nullable } from 'src/app/core/types/nullable.type';



@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanActivateChild {

  //#region Lifecycle

  constructor(
    private router: Router,
    private permissionsService: PermissionsService
  ) { }

  //#endregion

  //#region Methods

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async resolve => {
      const permission = this.getData(state.root);
      const authorized = await this.permissionsService.has(permission);

      if (!authorized) {
        this.router.navigate([`/${Page.Home}`], { replaceUrl: true })
      }

      resolve(authorized);
    });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  //#endregion

  //#region Methods

  /**
   * @description
   * Retrieves the permission from the navigated route
   *
   * @param state The starting navigation state
   * @returns The permission value
   */
  private getData(state: ActivatedRouteSnapshot, cache?: Nullable<TPermissions>): TPermissions {
    const data: TPermissions = state.data['permission'] ?? cache ?? 'none';

    if (state.firstChild != null) {
      return this.getData(state.firstChild, data);
    }

    return data;
  }

  //#endregion
}
