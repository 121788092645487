import { Component, Input } from '@angular/core';



@Component({
  selector: '[appLoader]',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  //#region

  /**
   * @description
   * The loading state
   */
  @Input()
  appLoader: boolean;

  //#endregion

  //#region Lifecycle

  constructor() {
    this.appLoader = false;
  }

  //#endregion
}
