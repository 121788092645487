import { IAppState } from '../app/app.type';
import { createSelector } from '@ngrx/store';
import { IProjectDetailState } from './project-detail.type';

export const selectProjectDetailState = (state: IAppState) =>
  state.projectDetail;

export const selectProjectDetailId = createSelector(
  selectProjectDetailState,
  (state: IProjectDetailState) => state.id
);
export const selectProjectInfo = createSelector(
  selectProjectDetailState,
  (state: IProjectDetailState) => state.projectInfo
);
export const selectProjectPages = createSelector(
  selectProjectDetailState,
  (state: IProjectDetailState) => state.projectPages
);
export const selectProjectComponents = createSelector(
  selectProjectDetailState,
  (state: IProjectDetailState) => state.projectComponents
);
export const selectProjectDetailLoading = createSelector(
  selectProjectDetailState,
  (state: IProjectDetailState) => state.isLoading
);
export const selectProjectPagesLoading = createSelector(
  selectProjectDetailState,
  (state: IProjectDetailState) => state.isLoadingPages
);
export const selectProjectComponentsLoading = createSelector(
  selectProjectDetailState,
  (state: IProjectDetailState) => state.isLoadingComponents
);
export const selectIsTestRun = createSelector(
  selectProjectDetailState,
  (state: IProjectDetailState) => state.isTestRun
);
