import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '../../services/toast/toast.service';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { EMPTY, catchError, first, switchMap, tap } from 'rxjs';
import { FeatureService } from 'src/app/core/api/feature/features.service';
import { IAppState } from 'src/app/state/app/app.type';
import { Store } from '@ngrx/store';
import { selectProjectInfo } from 'src/app/state/project-detail/project-detail.selectors';
import { setTestRun } from 'src/app/state/project-detail/project-detail.action';
import { IProject } from 'src/app/core/types/project.type';
@Component({
  selector: 'app-load-test-form',
  templateUrl: './load-test-form.component.html',
})
export class LoadTestFormComponent {
  public form: FormGroup;
  public get f(): any {
    return this.form.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<LoadTestFormComponent>,
    private _fb: FormBuilder,
    private _toastService: ToastService,
    private _featureService: FeatureService,
    private store: Store<IAppState>
  ) {
    this.form = this._fb.group({
      executorCount: [null],
      rampUsers: [null],
      duration: [null],
    });
  }

  onRun() {
    this.store
      .select(selectProjectInfo)
      .pipe(
        first(),
        tap(() => {
          this.store.dispatch(setTestRun({ isRun: true }));
          this._toastService.show('Test is running...', {
            type: ToastType.Normal,
            duration: 20000,
          });
        }),
        switchMap((project) =>
          this._featureService
            .runLoadTest(
              this.data.feature,
              project as IProject,
              this.form.value as any
            )
            .pipe(
              catchError((err) => {
                this.store.dispatch(setTestRun({ isRun: false }));
                this._toastService.show(`Could not run this test.`, {
                  type: ToastType.Error,
                });
                return EMPTY;
              }),
              tap(() => {
                this.store.dispatch(setTestRun({ isRun: false }));
                this._toastService.show(`Test runs successfully.`, {
                  type: ToastType.Success,
                });
                this.dialogRef.close();
              })
            )
        )
      )
      .subscribe();
  }
}
