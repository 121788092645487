<div class="dialog">
  <div mat-dialog-title class="dialog__head">
    {{ !isEdit ? "Create" : "Edit" }} Page
  </div>

  <div mat-dialog-content class="dialog__body">
    <form class="group relative" [formGroup]="form">
      <mat-form-field class="input" appearance="outline">
        <mat-icon matPrefix>badge</mat-icon>
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="Enter page name"
          (input)="onNameType($event)"
        />
        <mat-error *ngIf="f.name.errors?.required"
          >Page name is required</mat-error
        >
      </mat-form-field>

      <mat-form-field class="input" appearance="outline">
        <mat-icon matPrefix>link</mat-icon>
        <mat-label>Absolute URL / Relative URL</mat-label>
        <input matInput formControlName="url" placeholder="Enter page url" />
        <mat-error *ngIf="f.url?.errors?.required"
          >Page URL is required</mat-error
        >
        <mat-error *ngIf="f.url?.errors?.pattern"
          >Please enter a valid URL</mat-error
        >
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="dialog__foot">
    <button mat-button mat-dialog-close>Cancel</button>
    <ng-container *ngIf="!this.isEdit; else editTemplate">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!form.valid"
        (click)="createPage()"
      >
        Create
      </button>
    </ng-container>
    <ng-template #editTemplate>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!form.valid"
        (click)="editPage()"
      >
        Edit
      </button>
    </ng-template>
  </div>
</div>
