import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrl } from '../../enums/api-url.enum';

@Injectable({
  providedIn: 'root',
})
/**
 * @service
 * @description
 * Handles requests for the component controller
 */
export class ComponentApiService {
  //#region Lifecycle

  constructor(private _http: HttpClient) {}
  //#endregion

  //#region Methods

  /**
   * @description
   * Create new Component
   * @param component Component details to add
   */
  createNewComponent(component: any) {
    return this._http.post(`${ApiUrl?.Components}`, component);
  }
  /**
   * @description
   * Update an existing project component
   * @param component The component to update
   */
  updateExistingComponent(component: any) {
    return this._http.put(`${ApiUrl?.Components}`, component);
  }
  /**
   * @description
   * Get project components by Project ID
   * @param projectId project id to get components from
   */
  getComponentsByProjectId(projectId: string): Observable<any> {
    return this._http.get(`${ApiUrl?.Components}/${projectId}`);
  }
  /**
   * @description
   * Delete a project component
   * @param componentId The component id to delete
   */
  deleteComponenetById(componentId: string): Observable<any> {
    return this._http.delete(`${ApiUrl?.Components}/${componentId}`);
  }
  /**
   * @description
   * Attach child component to parent component
   * @param childId Child comonent Id
   * @param parentId Parent comonent Id
   */
  attachChildComponentToParentComponent(childId: string, parentId: string) {
    return this._http.post(
      `${ApiUrl?.Components}/attachToComponent/${childId}/${parentId}`,
      {}
    );
  }
  /**
   * @description
   * Dettach child component from parent component
   * @param childId Child comonent Id
   * @param parentId Parent comonent Id
   */
  detachChildComponentFromParentComponent(childId: string, parentId: string) {
    return this._http.post(
      `${ApiUrl?.Components}/detachFromComponent/${childId}/${parentId}`,
      {}
    );
  }
  /**
   * @description
   * Attach component to page
   * @param componentId  Comonent Id
   * @param pageId Page Id
   */
  attachComponentToPage(componentId: string, pageId: string) {
    return this._http.post(
      `${ApiUrl?.Components}/attachToPage/${componentId}/${pageId}`,
      {}
    );
  }
  /**
   * @description
   * Dettach component from page
   * @param componentId  Comonent Id
   * @param pageId Page Id
   */
  detachComponentFromPage(componentId: string, pageId: string) {
    return this._http.post(
      `${ApiUrl?.Components}/detachFromPage/${componentId}/${pageId}`,
      {}
    );
  }
}
