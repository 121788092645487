import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Page } from 'src/app/core/enums/page.enum';
import { AuthService } from '../../services/auth/auth.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  //#region Lifecycle

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  //#endregion

  //#region Methods

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(resolve => {
      this.authService.isLoggedIn().subscribe(authorized => {
        if (!authorized) {
          this.router.navigate([`/${Page.SignIn}`], { replaceUrl: true })
        }

        resolve(authorized);
      });
    });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  //#endregion
}
