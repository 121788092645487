/**
 * @enum
 * @description
 * Application page names
 */
export enum Page {

  /**
   * @description
   * The home page (layout)
   */
  Home = 'home',

  /**
   * @description
   * The user's page
   */
  Users = 'users',

  /**
   * @description
   * The login page
   */
  SignIn = 'sign-in',

  /**
   * @description
   * The reports page
   */
  Reports = 'reports',

  /**
   * @description
   * The projects page
   */
  Projects = 'projects',

  /**
   * @description
   * The dictionaries page
   */
  Dictionaries = 'dictionaries'
}