import { trigger, transition, style, query, animateChild, group, animate } from "@angular/animations";



export const fadeInAnimation = trigger('routeAnimations', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [style({ opacity: 1 })], { optional: true }),
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('200ms ease-out', style({ opacity: 0 }))], { optional: true }),
      query(':enter', [animate('200ms ease-out', style({ opacity: 1 }))], { optional: true }),
      query('@*', animateChild(), { optional: true })
    ]),
  ])
]);