import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { TPermissions } from 'src/app/core/types/permissions.type';
import { PermissionsService } from '../../services/permissions/permissions.service';



/**
 * @directive
 * @description
 * This directive manages element visibility by checking if
 * the connected user has access to said element by verifying
 * if their role includes the input permission.
 */
@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {

  //#region Properties

  /**
   * @description
   * The permission that concerns the element
   */
  @Input()
  appPermission!: TPermissions | Array<TPermissions>;

  //#endregion

  //#region Lifecycle

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService
  ) { }

  async ngOnInit(): Promise<void> {
    const allowed = await this.permissionsService.has(this.appPermission);

    if (allowed) {
      this.show();
    } else {
      this.hide();
    }
  }

  //#endregion

  //#region Methods

  /**
   * @description
   * Shows the element to the user.
   */
  private show(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  /**
   * @description
   * Hides the element from the user.
   */
  private hide(): void {
    this.viewContainer.clear();
  }

  //#endregion
}
