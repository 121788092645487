import { createAction, props } from '@ngrx/store';

export enum ProjectDetailAction {
  EmptyProjectDetail = '[Project Detail] Empty project detail',
  SetProjectId = '[Project Detail] Set Project Id',
  GetProjectInfo = '[Project Detail] Get Project Info',
  GetProjectInfoSuccess = '[Project Detail] Get Project Info Success',
  GetProjectInfoFailure = '[Project Detail] Get Project Info Failure',
  GetProjectPages = '[Project Detail] Get Project Pages',
  GetProjectPagesSuccess = '[Project Detail] Get Project Pages Success',
  GetProjectPagesFailure = '[Project Detail] Get Project Pages Failure',
  GetProjectComponents = '[Project Detail] Get Project Components',
  GetProjectComponentsSuccess = '[Project Detail] Get Project Components Success',
  GetProjectComponentsFailure = '[Project Detail] Get Project Components Failure',
  SetTestRun = '[Project Detail] Set Test run OFF / ON',
}

export const emptyProjectDetail = createAction(
  ProjectDetailAction.EmptyProjectDetail
);
export const setProjectId = createAction(
  ProjectDetailAction.SetProjectId,
  props<{ id: string | null }>()
);
export const getProjectInfo = createAction(
  ProjectDetailAction.GetProjectInfo,
  props<{ projectId: any }>()
);
export const getProjectInfoSuccess = createAction(
  ProjectDetailAction.GetProjectInfoSuccess,
  props<{ projectInfo: any }>()
);
export const getProjectInfoFailure = createAction(
  ProjectDetailAction.GetProjectInfoFailure,
  props<{ error: any }>()
);
export const getProjectPages = createAction(
  ProjectDetailAction.GetProjectPages,
  props<{ projectId: any }>()
);
export const getProjectPagesSuccess = createAction(
  ProjectDetailAction.GetProjectPagesSuccess,
  props<{ projectPages: any }>()
);
export const getProjectPagesFailure = createAction(
  ProjectDetailAction.GetProjectPagesFailure,
  props<{ error: any }>()
);
export const getProjectComponents = createAction(
  ProjectDetailAction.GetProjectComponents,
  props<{ projectId: any }>()
);
export const getProjectComponentsSuccess = createAction(
  ProjectDetailAction.GetProjectComponentsSuccess,
  props<{ projectComponents: any }>()
);
export const getProjectComponentsFailure = createAction(
  ProjectDetailAction.GetProjectComponentsFailure,
  props<{ error: any }>()
);
export const setTestRun = createAction(
  ProjectDetailAction.SetTestRun,
  props<{ isRun: boolean }>()
);
