import { Component } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { fadeInAnimation } from './shared/animations/fade-in.animation';
import { selectDarkMode } from './state/helper/helper.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from './state/app/app.type';
import { take, tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInAnimation],
})
export class AppComponent {
  //#region Lifecycle

  constructor(
    private contexts: ChildrenOutletContexts,
    private store: Store<IAppState>
  ) {
    this.store
      .select(selectDarkMode)
      .pipe(
        take(1),
        tap((isdark: boolean) => {
          if (isdark) document.documentElement.classList.add('dark');
        })
      )
      .subscribe();
  }

  //#endregion

  //#region Methods

  getRouteAnimationData(): any {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }

  //#endregion
}
