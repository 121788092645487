/**
 * @enum
 * @description
 * The user's roles
 */
export enum UserRole {

  /**
   * @description
   * The basic user role
   */
  User = 'USER',

  /**
   * @description
   * The administrator role
   */
  Admin = 'ADMIN',

  /**
   * @description
   * The maintainer role, above user and beneath admin
   */
  Maintainer = 'MAINTAINER'
}