import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrl } from '../../enums/api-url.enum';
@Injectable({
  providedIn: 'root',
})
/**
 * @service
 * @description
 * Handles requests for the pages controller
 */
export class PageApiService {
  //#region Lifecycle

  constructor(private _http: HttpClient) {}
  //#endregion

  //#region Methods

  /**
   * @description
   * Get project pages by Project ID
   * @param projectId project id to get pages from
   */
  getPagesByProjectId(projectId: string): Observable<any> {
    return this._http.get(`${ApiUrl?.Pages}/${projectId}`);
  }
  /**
   * @description
   * Create new project page
   * @param page The page to add
   */
  createNewPage(page: any) {
    return this._http.post(`${ApiUrl?.Pages}`, page);
  }
  /**
   * @description
   * Update an existing project page
   * @param page The page to update
   */
  updateExistingPage(page: any) {
    return this._http.put(`${ApiUrl?.Pages}`, page);
  }
  /**
   * @description
   * Delete a project page
   * @param pageId The page id to delete
   */
  deletePageById(pageId: string): Observable<any> {
    return this._http.delete(`${ApiUrl?.Pages}/${pageId}`);
  }

  //#endregion
}
