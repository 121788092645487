import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { Nullable } from 'src/app/core/types/nullable.type';
import { IToastConfig } from 'src/app/core/types/toast-config.type';


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  //#region Lifecycle

  constructor(private snackBar: MatSnackBar) { }

  //#endregion

  //#region Methods

  /**
   * @description
   * Raises a toast message
   *
   * @param message The message to show on the toast
   * @param config The configuration object
   */
  show(message: string, config?: Partial<IToastConfig>): void {
    const time = config?.duration ?? 3000;
    const vPosition = config?.vPosition ?? 'top';
    const hPosition = config?.hPosition ?? 'center';
    const actionLabel = config?.action ?? 'Dismiss';
    const typeClass = ['toast', `toast--${this.getTypeClass(config?.type)}`];

    const configuration = {
      duration: time,
      action: actionLabel,
      panelClass: typeClass,
      verticalPosition: vPosition,
      horizontalPosition: hPosition
    };

    this.snackBar.open(message, configuration.action, configuration);
  }

  /**
   * @description
   * Returns the proper class given a toast type
   *
   * @param type The type of toast
   */
  private getTypeClass(type: Nullable<ToastType>): string {
    switch (type) {
      case ToastType.Normal: return 'normal';
      case ToastType.Success: return 'success';
      case ToastType.Alert: return 'alert';
      case ToastType.Error: return 'error';
      default: return '';
    }
  }

  //#endregion
}
