import { on } from '@ngrx/store';
import { IReportState } from './report.type';
import { StorageKey } from 'src/app/core/enums/storage-key.enum';
import { createRehydrateReducer } from 'src/app/core/helpers/state.helper';
import {
  getProjectsSuccess,
  getProjects,
  getProjectsFailure,
} from './report.action';

export const initialState: IReportState = {
  projectList: [],
  loading: false,
  error: '',
};

export const reportReducer = createRehydrateReducer(
  StorageKey.Reports,
  initialState,

  on(getProjects, (state) => ({
    ...state,
    loading: true,
  })),

  on(getProjectsSuccess, (state, { projectList }) => ({
    ...state,
    error: '',
    projectList,
    loading: false,
  })),

  on(getProjectsFailure, (state, action) => ({
    ...state,
    loading: false,
    projectList: [],
    error: action.error,
  }))
);
