import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrl } from '../../enums/api-url.enum';
import { ILoginRequest } from '../../types/requests/login-request.type';
import { ILoginResponse } from '../../types/responses/login-response.type';



/**
 * @service
 * @description
 * Handles authentication requests
 */
@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  //#region Lifecycle

  constructor(private http: HttpClient) { }

  //#endregion

  //#region Methods

  /**
   * @description
   * Logs the user in
   *
   * @param email the email of the user
   * @param password the password of the user
   */
  login(email: string, password: string): Observable<ILoginResponse> {
    const credentials: ILoginRequest = { email: email, password: password };
    return this.http.post<ILoginResponse>(ApiUrl.Authenticate, credentials);
  }

  //#endregion
}
