import { UserRole } from "../enums/user-role.enum";
import { TPermissions } from "../types/permissions.type";



/**
 * @helper
 * @description
 * A collection of helpers for decorator use.
 */
export class DecoratorHelper {

  //#region Properties

  /**
   * @description
   * The key used to store the mapped permissions.
   */
  private static readonly permissionsKey = '__permissions__';

  //#endregion

  //#region Methods

  /**
   * @description
   * Registers a permission for a user role.
   *
   * @param target The host object that's gonna keep track of the permission
   * @param propertyKey The permission accessor key
   * @param role The user role that has the permission
   */
  static addPermission(target: any, propertyKey: TPermissions, role: UserRole): void {
    if (!target[DecoratorHelper.permissionsKey]) {
      target[DecoratorHelper.permissionsKey] = {};
    }

    if (!target[DecoratorHelper.permissionsKey][role]) {
      target[DecoratorHelper.permissionsKey][role] = [];
    }

    target[DecoratorHelper.permissionsKey][role].push(propertyKey);
  }

  /**
   * @description
   * Retrieves the list of permissions that belong to a user role.
   *
   * @param target The host object that keeps track of the permission
   * @param role The user role that has the permission
   */
  static getPermissions(target: any, role: UserRole): Array<TPermissions> {
    if (!target[DecoratorHelper.permissionsKey] || !target[DecoratorHelper.permissionsKey][role]) {
      return [];
    }

    return target[DecoratorHelper.permissionsKey][role];
  }

  /**
   * @description
   * Checks if a user role has access to a permission.
   *
   * @param target The host object that's storing the permission
   * @param propertyKey The permission accessor key
   * @param role The user role to check against
   * @returns True if the user role has the permission
   */
  static hasPermission(target: any, propertyKey: TPermissions, role: UserRole): boolean {
    if (target[DecoratorHelper.permissionsKey] && target[DecoratorHelper.permissionsKey][role]) {
      return target[DecoratorHelper.permissionsKey][role].indexOf(propertyKey) !== -1;
    }

    return false;
  }

  //#endregion
}