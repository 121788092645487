import { on } from '@ngrx/store';
import { IAuthState } from './auth.type';
import { login, loginFailure, loginInvalid, loginSession, loginSuccess, logout } from './auth.action';
import { StorageKey } from 'src/app/core/enums/storage-key.enum';
import { createRehydrateReducer } from 'src/app/core/helpers/state.helper';


export const initialState: IAuthState = {
  token: '',
  user: null,
  invalid: false,
  logingin: false
};

export const authReducer = createRehydrateReducer(
  StorageKey.Auth,
  initialState,
  on(login, state => ({ ...state, logingin: true })),
  on(loginInvalid, (state, action) => ({ ...state, invalid: action.invalid })),
  on(loginSuccess, (state, action) => ({ ...state, token: action.token, logingin: false })),
  on(loginSession, (state, action) => ({ ...state, user: action.user })),
  on(loginFailure, state => ({ ...state, logingin: false })),
  on(logout, state => ({ ...state, ...initialState }))
);