import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';



/**
 * @interceptor
 * @description
 * Intercepts requests and handles errors
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  //#region Lifecycle

  constructor() { }

  //#endregion

  //#region Methods

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  //#endregion
}
