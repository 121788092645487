import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { NewPageComponent } from './dialogs/new-page/new-page.component';
import { NewComponentComponent } from './dialogs/new-component/new-component.component';
import { PermissionModule } from './directives/permission/permission.module';
import { AttachComponent } from './dialogs/attach-component/attach-component.component';
import { EmptyComponent } from './components/empty/empty.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CloneProjectComponent } from './dialogs/clone-project/clone-project.component';
import { LoadTestFormComponent } from './dialogs/load-test-form/load-test-form.component';

@NgModule({
  declarations: [
    EmptyComponent,
    AttachComponent,
    LoaderComponent,
    NewPageComponent,
    NewComponentComponent,
    ConfirmationDialogComponent,
    CloneProjectComponent,
    LoadTestFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    PermissionModule,
  ],
  exports: [
    EmptyComponent,
    LoaderComponent,
    ConfirmationDialogComponent,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    PermissionModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule {}
