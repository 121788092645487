<div class="dialog">
  <div
    mat-dialog-title
    class="dialog__head"
  >
    {{ title }}
  </div>

  <div
    mat-dialog-content
    class="dialog__body"
  >
    <p>{{ message }}</p>
  </div>

  <div
    mat-dialog-actions
    class="dialog__foot"
  >
    <button
      mat-button
      mat-dialog-close
      mat-stroked-button
      class="button"
    >
      {{ cancelButtonText }}
    </button>

    <button
      mat-flat-button
      color="primary"
      class="button"
      (click)="onConfirm()"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</div>