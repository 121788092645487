import { Injectable } from '@angular/core';
import { switchMap, map, filter, catchError, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectApiService } from 'src/app/core/api/project/project-api.service';
import {
  getProjectInfo,
  getProjectInfoFailure,
  getProjectInfoSuccess,
  getProjectPages,
  getProjectPagesSuccess,
  getProjectPagesFailure,
  getProjectComponents,
  getProjectComponentsSuccess,
  getProjectComponentsFailure,
  setProjectId,
} from './project-detail.action';
import { of } from 'rxjs';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { PageApiService } from 'src/app/core/api/page/page-api.service';
import { ComponentApiService } from 'src/app/core/api/component/component-api.service';
import { IAppState } from '../app/app.type';
import { Store } from '@ngrx/store';

@Injectable()
export class ProjectDetailEffects {
  setProjectId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProjectId),
      map((option) => getProjectInfo({ projectId: option?.id }))
    )
  );

  getProjectsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectInfo),
      switchMap((option) =>
        this.ProjectApiService.getProjectById(+option.projectId).pipe(
          map((projectList) =>
            getProjectInfoSuccess({ projectInfo: projectList })
          ),
          catchError((err: any) => of(getProjectInfoFailure({ error: err })))
        )
      )
    )
  );
  getProjectInfoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getProjectInfoFailure),
        tap(() =>
          this.toastService.show(
            'Something went wrong while getting project details .',
            { type: ToastType.Error }
          )
        )
      ),
    { dispatch: false }
  );

  getProjectPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectPages),
      filter((f) => f.projectId),
      switchMap((projectId) =>
        this._pageApiService
          .getPagesByProjectId(projectId.projectId)

          .pipe(
            map((projectPages) =>
              getProjectPagesSuccess({ projectPages: projectPages })
            ),
            catchError((err: any) => of(getProjectPagesFailure({ error: err })))
          )
      )
    )
  );
  getProjectPagesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getProjectPagesFailure),
        tap(() =>
          this.toastService.show(
            'Something went wrong while getting project pages details .',
            { type: ToastType.Error }
          )
        )
      ),
    { dispatch: false }
  );
  getProjectComponents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectComponents),
      filter((f) => f.projectId),
      switchMap((projectId) =>
        this._componentApiService
          .getComponentsByProjectId(projectId.projectId)
          .pipe(
            map((projectComponents) =>
              getProjectComponentsSuccess({
                projectComponents: projectComponents,
              })
            ),
            catchError((err: any) =>
              of(getProjectComponentsFailure({ error: err }))
            )
          )
      )
    )
  );
  getProjectComponentsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getProjectComponentsFailure),
        tap(() =>
          this.toastService.show(
            'Something went wrong while getting project components details .',
            { type: ToastType.Error }
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private ProjectApiService: ProjectApiService,
    private _pageApiService: PageApiService,
    private _componentApiService: ComponentApiService,
    private toastService: ToastService
  ) {}
}
