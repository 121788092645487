import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Report, ReportsList } from '../../types/report-json.type';
import { ApiUrl } from "../../enums/api-url.enum";

@Injectable({
  providedIn: 'root',
})

/**
 * @service
 * @description
 * Handles requests for the report controller
 */
export class ReportsService {
  //#region Lifecycle
  constructor(private http: HttpClient) {}
  //#endregion

  //#region Methods
  /**
   * @description
   * Fetches a list of all projects
   */
  getProjects(): Observable<any> {
    return this.http.get(ApiUrl.Projects);
  }

  /**
   * @description
   * Get report info
   * @param projectId The project ID
   * @param offset offset
   */
  getReportsList(projectId: number, offset: number): Observable<ReportsList> {
    return this.http.get<ReportsList>(
      `${ApiUrl.Reports}/${projectId}?offset=${offset}`
    );
  }

  /**
   * @description
   * Get Detail feature
   * @param projectId The project ID
   * @param reportId The report ID
   */
  getSingleReport(reportId: number, projectId: number): Observable<Report> {
    return this.http.get<Report>(
      `${ApiUrl.Reports}/${projectId}/${reportId}`
    );
  }

  /**
   * @description
   * Delete feature
   * @param projectId The project ID
   * @param reportId The report ID
   */
  deleteFeature(reportId: number, projectId: number | null) { // TODO null è necessario?
    return this.http.delete(
      `${ApiUrl.Reports}/${projectId}/${reportId}`
    );
  }
}
