<ng-container *ngIf="!appEmpty; else content">
  <ng-content></ng-content>
</ng-container>

<ng-template #content>
  <div class="empty">
    <div class="empty__icon">
      <img
        alt="No data graphic"
        src="./assets/icons/no-data.svg"
      >
    </div>
    <div class="empty__message">
      {{ message }}
    </div>
  </div>
</ng-template>