<div class="dialog">
  <div mat-dialog-title class="dialog__head">Clone project</div>

  <div mat-dialog-content class="dialog__body">
    <mat-form-field appearance="outline" class="input input--compact mt-4 mb-4">
      <mat-icon matPrefix class="input__icon material-icons-outlined"
        >badge</mat-icon
      >

      <mat-label class="input__label">Name</mat-label>

      <input
        matInput
        class="input__field"
        [formControl]="projectNameCtrl"
        placeholder="Enter project name"
        (input)="onNameType($event)"
      />

      <mat-error
        *ngIf="projectNameCtrl.hasError('required')"
        class="input__error"
        >Project name is required</mat-error
      >

      <mat-error
        *ngIf="projectNameCtrl.hasError('nameIsSameAsClonedProject')"
        class="input__error"
        >Must not be 2 projects with the same name
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="dialog__foot">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!projectNameCtrl.valid"
      (click)="cloneProject()"
    >
      Clone
    </button>
  </div>
</div>
