/**
 * @enum
 * @description
 * The local storage keys
 */
export enum StorageKey {
  /**
   * @description
   * Authentication state key
   */
  Auth = 'eP9Idy2fYj',

  /**
   * @description
   * Reports state key
   */
  Reports = 'c9u02jEKCB',
  /**
   * @description
   * Helper state key
   */
  helper = 'e56jjfy2fYj',
}
