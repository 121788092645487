import { UserRole } from "../enums/user-role.enum";
import { IUser } from "../types/user.type";



/**
 * @model
 * @description
 * The basic user object
 */
export class User implements IUser {

  //#region Properties

  /**
   * @description
   * The user's email
   */
  email: string;

  /**
   * @description
   * The user's role
   */
  role: UserRole;

  /**
   * @description
   * The user's projects
   */
  projects: Array<{ id: number }>;

  //#endregion

  //#region Lifecycle

  constructor(model?: Partial<IUser>) {
    this.email = model?.email ?? '';
    this.role = model?.role ?? UserRole.User;
    this.projects = model?.projects ?? [];
  }

  //#endregion
}